import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Process = makeShortcode("Process");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const Column = makeShortcode("Column");
const Box = makeShortcode("Box");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-module-0---an-introduction-to-kernel",
      "style": {
        "position": "relative"
      }
    }}>{`🌠 Module 0 - An Introduction to Kernel`}<a parentName="h1" {...{
        "href": "#-module-0---an-introduction-to-kernel",
        "aria-label": " module 0   an introduction to kernel permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Kernel is supported by this 8 module digital book intended to teach you to think about and build a better web. `}</p>
    <p>{`Each module is made up of two sections: `}<strong parentName="p">{`Crafted Reading`}</strong>{`, which we encourage you to
have read before the beginning of each new week; and `}<strong parentName="p">{`Curated Briefs`}</strong>{` which are made up of
articles, videos, and the occasional podcast that we `}<em parentName="p">{`highly recommend`}</em>{` you go over before the
Fireside Chat each Thursday.`}</p>
    <p>{`The book aims to provide a more holistic framing of what Web3 is — its principles,
history, and present state. We know that different people learn differently, hence the mix of
text, video and audio. The "Crafted Readings" have emojis next to them in the menu and you will need
to read both pieces to participate fully in each week's activities. `}</p>
    <h2 {...{
      "id": "a-brief-word",
      "style": {
        "position": "relative"
      }
    }}>{`A Brief Word`}<a parentName="h2" {...{
        "href": "#a-brief-word",
        "aria-label": "a brief word permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`The curated briefs are much more paradoxical documents in that they tend to be quite long. `}<em parentName="p">{`Do I contradict myself? Very well, I contradict myself. I am large, I contain multitudes`}</em>{`.`}</p>
    <p>{`Each brief begins with a few scene-setting sentences and then a section called “How Does This Fit Into Kernel?”, meant to describe succinctly why this particular piece has been included in our work and what role it plays in the larger learning arc we present. The source material for each brief is embedded directly into the top of each page so that you can explore it in more detail if the brief itself resonates with you, and because we understand that many people do not learn best by parsing long walls of text. `}</p>
    <p>{`We have been very careful in curating these pieces of content and not others because: `}</p>
    <Process mdxType="Process">
      <p>{`The `}<a parentName="p" {...{
          "href": "/learn/module-3/remember/#selecting-the-trail"
        }}>{`act of curation`}</a>{` itself in hypermedia is already deeply meaningful`}</p>
      <p>{`We intentionally do not want to reproduce "awesome lists" here that present you with hundreds of links no-one ever reads. Such lists often induce anxiety rather than providing the `}<a parentName="p" {...{
          "href": "/learn/module-6/learn"
        }}>{`inspiration to learn`}</a>{` about `}<a parentName="p" {...{
          "href": "/learn/module-4/self-enquiry"
        }}>{`who you really are`}</a>{` and what matters most in the context of your one wild and precious life. `}</p>
    </Process>
    <p>{`More than anything, we hope our briefs will help you find your own, unique way in a manner that allows you to share the journey heartfully with others.`}</p>
    <p>{`In this hyperconnected context, briefs are largely made up of others’ words. Through paying deep attention and truly listening to others, I can represent the ways we are all entangled with this world and show, simply by virtue of these stitched together tattered tapestries of text, how deeply and unspeakably intimate this life is.`}</p>
    <p>{`Though we all speak our heart differently - and these differences are to be discerned, respected, and celebrated - there is, even in our deepest conflicts, a generativity, a generosity, an abundance that sings of how there is `}<a parentName="p" {...{
        "href": "https://www.un-intelligible.org/projects/knowledg/22metdis.php"
      }}>{`life in dissent`}</a>{`, too. When we really know this and can be with it, can with-ness it, without irritation - without even tolerance - then we can remember truly how this life comes from, overflows, and returns to, One.`}</p>
    <p>{`With all that said, and before we begin in earnest, we’d like to consider for a moment the kind of conversations we have been carefully cultivating.`}</p>
    <h2 {...{
      "id": "spaced-repetition",
      "style": {
        "position": "relative"
      }
    }}>{`Spaced Repetition`}<a parentName="h2" {...{
        "href": "#spaced-repetition",
        "aria-label": "spaced repetition permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Based on work we'll cover in module 3, Kernel aims to present learning in a new kind of medium which integrates a spaced-repetition memory system. This means you'll need to return to do future reviews. In a conventional course, you’d forget most of what you learned over time, perhaps retaining a handful of ideas. However, with spaced repetition built into the medium, a small additional commitment of time means you will remember significantly more. Doing this won’t be difficult, it will be easier than the initial read. Furthermore, you’ll be able to read other material which builds on these ideas. It will open up an entire world.`}</p>
    <p>{`Please indulge us by playing with the prompts you find in the text. For each prompt, think about what you remember, click to reveal the reminder, and then mark whether you remembered or not. If you can recall, that’s great. If not, that’s also fine, just note the reminder and continue.`}</p>
    <p>{`This spaced-repetition approach is why the prompts only require a few seconds to read and answer. They’re not complex exercises, in the style of a textbook. Rather, the prompts have a different point: to help us help each other remember.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: What is my purpose?`}</p>
        <p>{`To help us remember (And to pass the butter, of course)`}</p>
      </Card>
    </Flash>
    <h2 {...{
      "id": "cultured-conversation",
      "style": {
        "position": "relative"
      }
    }}>{`Cultured Conversation`}<a parentName="h2" {...{
        "href": "#cultured-conversation",
        "aria-label": "cultured conversation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Meaningful conversation can only take place in a culture of respect and courtesy, between people
who are capable of seeing their highest ideals in others and who can recognise and reflect the
essential sameness which lies at the heart of each and every human being. `}</p>
    <p>{`Cultured conversations tend to resist the temptation to engage in dialogues with the intention to “take something away", be it new knowledge, an altered perspective, or an inside story we can leverage for our own gain. Kernel conversations are about `}<em parentName="p">{`humility`}</em>{`, `}<em parentName="p">{`presence`}</em>{` and a `}<em parentName="p">{`genuine attentiveness`}</em>{` towards others, not because you stand to benefit from them, but because cultivating these three capacities within yourself is its own reward, which may allow you to see clearly the complete and perfect sacred core of every person you encounter.`}</p>
    <p>{`What’s perfectly whole seems flawed,`}<br parentName="p"></br>{`
`}{`but you can use it forever.`}<br parentName="p"></br>{`
`}{`What’s perfectly full seems empty,`}<br parentName="p"></br>{`
`}{`but you can’t use it up.  `}</p>
    <p>{`True straightness looks crooked.`}<br parentName="p"></br>{`
`}{`Great skill looks clumsy.`}<br parentName="p"></br>{`
`}{`Real eloquence seems to stammer.  `}</p>
    <p>{`To be comfortable in the cold, keep moving;`}<br parentName="p"></br>{`
`}{`to be comfortable in the heat, hold still;`}<br parentName="p"></br>{`
`}{`to be comfortable in the world, stay calm and clear. `}</p>
    <p>{`You can find many other (flawed) translations of these clumsy words `}<a parentName="p" {...{
        "href": "https://ttc.tasuki.org/display:Code:gff,sm,jhmd,jc,rh/section:45"
      }}>{`here`}</a>{`.`}</p>
    <h2 {...{
      "id": "week-0-firesides",
      "style": {
        "position": "relative"
      }
    }}>{`Week 0 Firesides`}<a parentName="h2" {...{
        "href": "#week-0-firesides",
        "aria-label": "week 0 firesides permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Column sx={{
      gap: "10%"
    }} mdxType="Column">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Vitalik Buterin - February 10, 2022`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/Sgu3WFusS98?start=232" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Vitalik Buterin - September 30, 2021`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/zUnoKcDCnx4?start=406" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Andy Tudhope - May 20, 2021`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/6uVcpfVvB3c?start=325" mdxType="Video" />
      </Box>
    </Column>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      